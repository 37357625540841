var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '../../assets/svg/close.svg';
import ReactDOM from 'react-dom';
import { styled } from '@mui/material/styles';
import { LayoutStyles } from '../../styles/common/layout';
export var ModalOverlay = function (props) {
    var isActive = props.isActive, toggleModal = props.toggleModal, Content = props.Content, width = props.width, zIndex = props.zIndex, restProps = __rest(props, ["isActive", "toggleModal", "Content", "width", "zIndex"]);
    var component = (_jsxs(ModalMain, __assign({}, restProps, { isActive: isActive, width: width, zIndex: zIndex }, { children: [_jsx(ContentWrapper, __assign({ id: "modal" }, { children: Content })), _jsx(CloseButton, __assign({ onClick: toggleModal }, { children: _jsx(CloseIcon, {}) }))] })));
    var container = document.getElementById('modal-portal');
    return ReactDOM.createPortal(component, container);
};
// Styles
var ModalMain = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 7rem;\n  left: ", "%;\n  z-index: ", ";\n\n  display: ", ";\n\n  align-items: center;\n  justify-content: center;\n\n  width: ", "%;\n"], ["\n  position: fixed;\n  top: 7rem;\n  left: ", "%;\n  z-index: ", ";\n\n  display: ", ";\n\n  align-items: center;\n  justify-content: center;\n\n  width: ", "%;\n"])), function (_a) {
    var width = _a.width;
    return (100 - width) / 2;
}, function (_a) {
    var zIndex = _a.zIndex;
    return zIndex * 1001;
}, function (_a) {
    var isActive = _a.isActive;
    return isActive ? 'block' : 'none';
}, function (_a) {
    var width = _a.width;
    return width;
});
var ContentWrapper = styled(LayoutStyles.Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-height: 90vh;\n  overflow-x: auto;\n"], ["\n  max-height: 90vh;\n  overflow-x: auto;\n"])));
var CloseButton = styled('div')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: -3rem;\n  right: -3rem;\n\n  width: 3rem;\n  height: 3rem;\n\n  border-radius: 50%;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n  cursor: pointer;\n\n  & svg {\n    position: absolute;\n    top: .35rem;\n    right: .35rem;\n\n    width: 2.25rem;\n    height: 2.25rem;\n\n    fill: ", ";\n  }\n\n  &:hover {\n    & svg {\n      fill: ", "\n    }\n  }\n"], ["\n  position: absolute;\n  top: -3rem;\n  right: -3rem;\n\n  width: 3rem;\n  height: 3rem;\n\n  border-radius: 50%;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n  cursor: pointer;\n\n  & svg {\n    position: absolute;\n    top: .35rem;\n    right: .35rem;\n\n    width: 2.25rem;\n    height: 2.25rem;\n\n    fill: ", ";\n  }\n\n  &:hover {\n    & svg {\n      fill: ", "\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.common.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.shadows[2];
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary.main;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.secondary.main;
});
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { usersACTION, UsersAT } from './actions';
import { deleteRequest, getRequest, postRequest, RequestStatus } from '../../../core/api/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { standardError } from '../../../helpers/sagaHelpers';
// * Workers
export function getUsersWorker() {
    var resUsers, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, put(usersACTION.load())];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(getRequest, 'users')];
            case 2:
                resUsers = _a.sent();
                return [4 /*yield*/, put(usersACTION.storeUsers(resUsers))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(usersACTION.success())];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_1 = _a.sent();
                return [4 /*yield*/, standardError(error_1, UsersAT.FAIL)];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function editOrUpdateUserWorker(action) {
    var user, resUser, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                user = action.payload.user;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                return [4 /*yield*/, put(usersACTION.load())];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(postRequest, 'users', user)];
            case 3:
                resUser = _a.sent();
                return [4 /*yield*/, put(usersACTION.storeSingleUser(resUser))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(usersACTION.success())];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, standardError(error_2, UsersAT.FAIL)];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function deleteUserWorker(action) {
    var userId, resDeletedUser, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userId = action.payload.userId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                return [4 /*yield*/, put(usersACTION.load())];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(deleteRequest, 'users', "id=".concat(userId))];
            case 3:
                resDeletedUser = _a.sent();
                return [4 /*yield*/, put(usersACTION.unStoreUser(resDeletedUser.id))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(usersACTION.success())];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                error_3 = _a.sent();
                return [4 /*yield*/, standardError(error_3, UsersAT.FAIL)];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
// * Watchers
export function watcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(UsersAT.GET_USERS, getUsersWorker)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(UsersAT.ADD_OR_EDIT_USER, editOrUpdateUserWorker)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(UsersAT.DELETE_USER, deleteUserWorker)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var initialState = {
    users: null,
    status: RequestStatus.still
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UsersAT.LOAD: {
            return __assign(__assign({}, state), { status: RequestStatus.loading });
        }
        case UsersAT.SUCCESS: {
            return __assign(__assign({}, state), { status: RequestStatus.still });
        }
        case UsersAT.FAIL: {
            return __assign(__assign({}, state), { status: RequestStatus.fail });
        }
        case UsersAT.STORE_SINGLE_USER: {
            var user_1 = action.payload.user;
            // If users don't exist, add the user.
            if (state.users === null || state.users.length === 0) {
                return __assign(__assign({}, state), { users: [user_1] });
            }
            else {
                // If user exists replace it with updated user, else add user to users.
                var userExists = !!state.users.find(function (stateUser) { return stateUser.id === user_1.id; });
                if (userExists) {
                    return __assign(__assign({}, state), { users: state.users.map(function (stateUser) { return (stateUser.id === user_1.id ? user_1 : stateUser); }) });
                }
                else {
                    return __assign(__assign({}, state), { users: __spreadArray(__spreadArray([], state.users, true), [user_1], false) });
                }
            }
        }
        case UsersAT.STORE_USERS: {
            var users = action.payload.users;
            return __assign(__assign({}, state), { users: users });
        }
        case UsersAT.UN_STORE_USER: {
            var userId_1 = action.payload.userId;
            if (state.users === null || state.users.length === 0) {
                return state;
            }
            else {
                return __assign(__assign({}, state), { users: state.users.filter(function (stateUser) { return stateUser.id !== userId_1; }) });
            }
        }
        default:
            return state;
    }
}

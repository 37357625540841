var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { TypographyStyles } from '../../../core/styles/common/typography';
export var CriticalErrorModalContent = memo(function (props) {
    var error = props.error;
    // Styles
    var TS = TypographyStyles;
    return (_jsx(_Fragment, { children: error &&
            _jsxs(ErrorWrapper, { children: [_jsx(TS.Title, __assign({ marginBottom: "m" }, { children: error.message })), _jsxs(Error, { children: [_jsx("span", { children: "Error code: " }), _jsx(ErrorCode, { children: error.incidentId })] }), _jsxs(TS.Paragraph, { children: ["Seems like something went wrong. If this happens again please take a screenshot and send to ", _jsx("a", __assign({ href: "mailto:support@denominator.one" }, { children: "support@denominator.one" }))] })] }) }));
});
// Styles
var ErrorWrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n"], ["\n  overflow: auto;\n"])));
var Error = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var ErrorCode = styled('span')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  color: ", ";\n"], ["\n  font-weight: 500;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.error.main;
});
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 } from 'uuid';
import { AppAT } from './actions';
export var initialState = {
    error: null,
    notifications: []
};
export var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AppAT.CLEAR_ERROR: {
            return __assign(__assign({}, state), { error: null });
        }
        case AppAT.SET_ERROR: {
            var _a = action.payload, message = _a.message, incidentId = _a.incidentId;
            return __assign(__assign({}, state), { error: { message: message, incidentId: incidentId } });
        }
        case AppAT.ADD_NOTIFICATION: {
            var _b = action.payload, type = _b.type, message = _b.message;
            return __assign(__assign({}, state), { notifications: __spreadArray(__spreadArray([], state.notifications, true), [{ id: v4(), type: type, message: message }], false) });
        }
        case AppAT.DELETE_NOTIFICATION: {
            var id_1 = action.payload.id;
            return __assign(__assign({}, state), { notifications: state.notifications.filter(function (note) { return note.id !== id_1; }) });
        }
        default:
            return state;
    }
};

export var UsersAT;
(function (UsersAT) {
    UsersAT["LOAD"] = "users/LOAD";
    UsersAT["SUCCESS"] = "users/SUCCESS";
    UsersAT["FAIL"] = "users/FAIL";
    UsersAT["GET_USERS"] = "users/GET_USERS";
    UsersAT["ADD_OR_EDIT_USER"] = "users/ADD_OR_EDIT_USER";
    UsersAT["DELETE_USER"] = "users/DELETE_USER";
    UsersAT["STORE_SINGLE_USER"] = "user/STORE_SINGLE_USER";
    UsersAT["STORE_USERS"] = "user/STORE_USERS";
    UsersAT["UN_STORE_USER"] = "user/UN_STORE_USER"; // Remove user from state.
})(UsersAT || (UsersAT = {}));
export var usersACTION = {
    load: function () { return ({
        type: UsersAT.LOAD
    }); },
    success: function () { return ({
        type: UsersAT.SUCCESS
    }); },
    fail: function () { return ({
        type: UsersAT.FAIL
    }); },
    getUsers: function () { return ({
        type: UsersAT.GET_USERS
    }); },
    addOrEditUser: function (user) { return ({
        type: UsersAT.ADD_OR_EDIT_USER,
        payload: {
            user: user
        }
    }); },
    deleteUser: function (userId) { return ({
        type: UsersAT.DELETE_USER,
        payload: {
            userId: userId
        }
    }); },
    storeSingleUser: function (user) { return ({
        type: UsersAT.STORE_SINGLE_USER,
        payload: {
            user: user
        }
    }); },
    storeUsers: function (users) { return ({
        type: UsersAT.STORE_USERS,
        payload: {
            users: users
        }
    }); },
    unStoreUser: function (userId) { return ({
        type: UsersAT.UN_STORE_USER,
        payload: {
            userId: userId
        }
    }); }
};

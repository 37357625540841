var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppRouter } from './router/AppRouter';
import { theme } from '../../core/styles/theme';
import { Provider } from 'react-redux';
import { store } from '../../core/BLL/store';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { NotificationController } from './NotificationController';
import { global } from '../../core/styles/global';
import { normalize } from '../../core/styles/normalize';
var inputNormalizeStyles = _jsx(GlobalStyles, { styles: normalize });
var inputGlobalStyles = _jsx(GlobalStyles, { styles: global });
export var appDecorator = function (Component) { return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [inputNormalizeStyles, inputGlobalStyles, _jsx(Provider, __assign({ store: store }, { children: Component }))] }))); };
export var App = function () {
    return (_jsx(_Fragment, { children: appDecorator(_jsxs(_Fragment, { children: [_jsx(NotificationController, {}), _jsx(AppRouter, {})] })) }));
};

import { combineReducers } from 'redux';
import { reducer as app } from '../../features/app/_BLL/reducer';
import { reducer as auth } from '../../features/auth/_BLL/reducer';
import { reducer as users } from '../../features/users/_BLL/reducer';
import { reducer as searchCompanies } from '../../features/users/_BLL/searchCompanies/reducer';
export var rootReducer = combineReducers({
    app: app,
    auth: auth,
    users: users,
    searchCompanies: searchCompanies
});

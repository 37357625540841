var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { PasswordInput } from '../../../core/components/ui-elements/inputs/controlled/PasswordInput';
import { RequestStatus } from '../../../core/api/api';
import { ButtonSquared } from '../../../core/components/ui-elements/buttons/buttons';
import { Preloader } from '../../../core/components/ui-elements/loaders/Preloader';
import { authAction } from '../_BLL/actions';
import { useActions } from '../../../core/BLL/createAction';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StringInput } from '../../../core/components/ui-elements/inputs/controlled/StringInput';
import { styled } from '@mui/material/styles';
export var SignInForm = function () {
    // Selectors
    var status = useSelector(function (state) { return state.auth; }).status;
    // Actions
    var signIn = useActions(authAction).signIn;
    var schema = Yup.object().shape({
        username: Yup.string().required(),
        password: Yup.string().required(),
    });
    var _a = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
        resolver: yupResolver(schema)
    }), handleSubmit = _a.handleSubmit, control = _a.control;
    var onSubmit = function (values) {
        signIn(values);
    };
    return (_jsxs(FormStyled, __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(Controller, { name: "username", control: control, render: function (_a) {
                    var _b, _c;
                    var field = _a.field, fieldState = _a.fieldState;
                    return (_jsx(StringInput, __assign({}, field, { variant: "outlined", label: "Username", size: "small", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message })));
                } }), _jsx(Controller, { name: "password", control: control, render: function (_a) {
                    var _b, _c;
                    var field = _a.field, fieldState = _a.fieldState;
                    return (_jsx(PasswordInput, __assign({}, field, { label: "Password", variant: "outlined", size: "small", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message })));
                } }), status === RequestStatus.loading ?
                _jsx(PreloaderWrapper, { children: _jsx(Preloader, {}) })
                :
                    _jsx(ButtonSquared, __assign({ type: "submit" }, { children: "Sign in" }))] })));
};
// Styles
var FormStyled = styled('form')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  & > div {\n    margin-bottom: 1rem;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  & > div {\n    margin-bottom: 1rem;\n  }\n"])));
var PreloaderWrapper = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: center;\n\n  width: 100%;\n"])));
var templateObject_1, templateObject_2;

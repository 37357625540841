export var ActionType;
(function (ActionType) {
    ActionType["LOAD"] = "searchCompanies/LOAD";
    ActionType["SUCCESS"] = "searchCompanies/SUCCESS";
    ActionType["FAIL"] = "searchCompanies/FAIL";
    ActionType["SEARCH"] = "searchCompanies/SEARCH";
    ActionType["SAVE_COMPANIES"] = "searchCompanies/SEARCH_SUCCESS";
    ActionType["SAVE_SEARCH"] = "searchCompanies/SAVE_SEARCH";
    ActionType["LOOKUP_CLEAR_ALL"] = "searchCompanies/LOOKUP_CLEAR_ALL";
    ActionType["CLEAR_RESULTS"] = "searchCompanies/CLEAR_RESULTS";
    ActionType["PAGINATION_INCREMENT"] = "searchCompanies/PAGINATION_INCREMENT";
})(ActionType || (ActionType = {}));
export var searchCompaniesAction = {
    load: function () { return ({
        type: ActionType.LOAD
    }); },
    success: function () { return ({
        type: ActionType.SUCCESS
    }); },
    fail: function () { return ({
        type: ActionType.FAIL
    }); },
    searchRequest: function (url, body) { return ({
        type: ActionType.SEARCH,
        payload: {
            url: url,
            body: body
        }
    }); },
    saveCompanies: function (companies, pagination) { return ({
        type: ActionType.SAVE_COMPANIES,
        payload: {
            companies: companies,
            pagination: pagination
        }
    }); },
    saveSearch: function (search) { return ({
        type: ActionType.SAVE_SEARCH,
        payload: {
            search: search
        }
    }); },
    clearAll: function () { return ({
        type: ActionType.LOOKUP_CLEAR_ALL
    }); },
    clearResults: function () { return ({
        type: ActionType.CLEAR_RESULTS
    }); },
    paginationIncrement: function () { return ({
        type: ActionType.PAGINATION_INCREMENT
    }); },
};

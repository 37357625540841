var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css, styled } from '@mui/material/styles';
import { TypographyStyles } from '../../core/styles/common/typography';
import { Controller, useFormContext } from 'react-hook-form';
import RemoveIcon from '../../core/assets/svg/close.svg';
export var CompaniesList = function (props) {
    var title = props.title;
    // * Form
    var _a = useFormContext(), control = _a.control, setValue = _a.setValue;
    var getCompanies = function (fieldValue) {
        return fieldValue.filter(function (company) { return company.accessType === (title.includes('Advanced') ? 1 : 2); });
    };
    var removeCompany = function (formValueCompanies, companyToRemove) {
        var remainingCompanies = formValueCompanies.filter(function (company) { return !(company.id === companyToRemove.id && company.accessType === companyToRemove.accessType); });
        setValue('copperCompanies', remainingCompanies);
    };
    return (_jsx(Controller, { control: control, name: "copperCompanies", render: function (_a) {
            var field = _a.field;
            return (_jsxs(_Fragment, { children: [_jsx(Title, { children: title }), field.value.length > 0
                        ?
                            _jsx(List, { children: getCompanies(field.value).map(function (company) { return (_jsxs(ListItem, { children: [_jsx("span", { children: "\n\t\t\t\t\t\t\t\tid: ".concat(company.id, "\n\t\t\t\t\t\t\t\tname: ").concat(company.displayName ? company.displayName : 'Unnamed', "\n\t\t\t\t\t\t\t\t") }), _jsx(RemoveButton, __assign({ onClick: function () { return removeCompany(field.value, company); } }, { children: _jsx(RemoveIcon, {}) }))] }, company.id)); }) })
                        :
                            _jsx("span", { children: "No Companies" })] }));
        } }));
};
// * Styles
var Title = styled(TypographyStyles.Title, { name: 'Title' })(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .6rem;\n  font-size: .8rem;\n"], ["\n  margin-bottom: .6rem;\n  font-size: .8rem;\n"]))));
var List = styled('ul', { name: 'List' })(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 35rem;\n  overflow-y: auto;\n\n  & > li:not(:last-child) {\n    margin-bottom: .5rem;\n  }\n"], ["\n  height: 35rem;\n  overflow-y: auto;\n\n  & > li:not(:last-child) {\n    margin-bottom: .5rem;\n  }\n"]))));
var ListItem = styled('li', { name: 'ListItem' })(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n\n  // TODO: LayoutStyles Paper\n  padding: 1rem;\n\n  background-color: ", ";\n  border-radius: ", ";\n  border: 1px solid ", ";\n\n  & span {\n    width: 90%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n\n  // TODO: LayoutStyles Paper\n  padding: 1rem;\n\n  background-color: ", ";\n  border-radius: ", ";\n  border: 1px solid ", ";\n\n  & span {\n    width: 90%;\n  }\n"])), theme.palette.common.white, theme.shape.borderRadius + 'px', theme.palette.custom.mainBorder);
});
var RemoveButton = styled('div', { name: 'RemoveButton' })(function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 1rem;\n  height: 1rem;\n\n  cursor: pointer;\n\n  & svg {\n    width: inherit;\n    height: inherit;\n    fill: ", ";\n  }\n"], ["\n  width: 1rem;\n  height: 1rem;\n\n  cursor: pointer;\n\n  & svg {\n    width: inherit;\n    height: inherit;\n    fill: ", ";\n  }\n"])), theme.palette.primary.main);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

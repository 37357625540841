export var AppAT;
(function (AppAT) {
    // Global error
    AppAT["CLEAR_ERROR"] = "app/CLEAR_ERROR";
    AppAT["SET_ERROR"] = "app/SET_ERROR";
    // Notifications
    AppAT["ADD_NOTIFICATION"] = "app/ADD_NOTIFICATION_SUCCESS";
    AppAT["DELETE_NOTIFICATION"] = "app/DELETE_NOTIFICATION";
})(AppAT || (AppAT = {}));
export var appAction = {
    clearError: function () { return ({
        type: AppAT.CLEAR_ERROR
    }); },
    setError: function (message, incidentId) { return ({
        type: AppAT.SET_ERROR,
        payload: {
            message: message,
            incidentId: incidentId
        }
    }); },
    addNotification: function (type, message) { return ({
        type: AppAT.ADD_NOTIFICATION,
        payload: {
            type: type,
            message: message
        }
    }); },
    deleteNotification: function (id) { return ({
        type: AppAT.DELETE_NOTIFICATION,
        payload: {
            id: id
        }
    }); }
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
export var UserSearch = function (props) {
    var filterUsers = props.filterUsers;
    var users = useSelector(function (state) { return state.users.users; });
    var _a = useState(''), userName = _a[0], setUserName = _a[1];
    var handleChangeFilter = function (event) {
        setUserName(event.currentTarget.value);
    };
    useEffect(function () {
        filterUsers(userName);
    }, [userName]);
    useEffect(function () {
        // reset filter when users change
        setUserName('');
    }, [users]);
    return (_jsx(Container, { children: _jsx(TextField, { size: "small", label: "filter by login", value: userName, onChange: function (e) { return handleChangeFilter(e); } }) }));
};
// * Styles
var Container = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop } from './Backdrop';
import { ModalOverlay } from './ModalOverlay';
export var Modal = function (props) {
    var show = props.show, toggleModal = props.toggleModal, Content = props.Content, _a = props.closeOnBackdrop, closeOnBackdrop = _a === void 0 ? true : _a, _b = props.width, width = _b === void 0 ? 80 : _b, // Percent
    _c = props.zIndex, // Percent
    zIndex = _c === void 0 ? 1 : _c, restProps = __rest(props, ["show", "toggleModal", "Content", "closeOnBackdrop", "width", "zIndex"]);
    return (_jsxs(_Fragment, { children: [_jsx(Backdrop, { isActive: show, onClick: closeOnBackdrop && toggleModal, zIndex: zIndex }), _jsx(ModalOverlay, __assign({}, restProps, { isActive: show, toggleModal: toggleModal, Content: Content, width: width, zIndex: zIndex }))] }));
};

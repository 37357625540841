var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ButtonRounded } from '../../../ui-elements/buttons/buttons';
import { authAction } from '../../../../../features/auth/_BLL/actions';
import { useActions } from '../../../../BLL/createAction';
import { LayoutStyles } from '../../../../styles/common/layout';
import { styled } from '@mui/material/styles';
export var HeaderProfile = function (props) {
    var userData = props.userData;
    // Actions
    var logout = useActions(authAction).logout;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    return (_jsxs(ProfileMain, __assign({ isOpen: open }, { children: [_jsx(UserNameContainer, __assign({ onClick: function () { return setOpen(!open); } }, { children: _jsx(UserName, { children: 'displayName' in userData ? userData.displayName : 'User' }) })), open && (_jsx(Menu, { children: _jsx(ButtonRounded, __assign({ onClick: logout }, { children: "Logout" })) }))] })));
};
// Styles
var ProfileMain = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 1.2rem;\n\n  cursor: pointer\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 1.2rem;\n\n  cursor: pointer\n"])));
var UserNameContainer = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n\n  margin-right: 1rem;\n"], ["\n  display: flex;\n\n  margin-right: 1rem;\n"])));
var UserName = styled('span')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: .5rem;\n\n  font-size: 1.2rem;\n  line-height: 1.4rem;\n"], ["\n  margin-right: .5rem;\n\n  font-size: 1.2rem;\n  line-height: 1.4rem;\n"])));
var Menu = styled(LayoutStyles.Card)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 6rem;\n  right: 1rem;\n"], ["\n  position: fixed;\n  top: 6rem;\n  right: 1rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

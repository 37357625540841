import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
export var createAction = function (type, payload) {
    return { type: type, payload: payload };
};
export var useActions = function (actions) {
    var dispatch = useDispatch();
    return useMemo(function () {
        return bindActionCreators(actions, dispatch);
    }, [actions, dispatch]);
};

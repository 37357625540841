var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { HeaderProfile } from './components/HeaderProfile';
import { Logo } from '../../ui-elements/Logo';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { RouterPath } from '../../../../features/app/router/AppRouter';
export var Header = function () {
    var navigate = useNavigate();
    // Selectors
    var _a = useSelector(function (state) { return state.auth; }), userData = _a.userData, status = _a.status;
    // Functions
    var onLogoClick = function () { return navigate(RouterPath.mainPage); };
    return (_jsxs(HeaderMain, { children: [_jsx(HeaderLeft, { children: _jsx(LogoStyled, { onClick: onLogoClick }) }), userData && (_jsx(HeaderProfile, { userData: userData }))] }));
};
// Styles
var HeaderMain = styled('header')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 500;\n\n  display: flex;\n  justify-content: space-between;\n  padding: .8rem 2rem;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n\n  height: 8vh;\n\n  // 1280 - 1919\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 7vh;\n  }\n\n  // 960 - 1279\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 6vh;\n  }\n\n  // 600 - 959\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 5vh;\n  }\n\n  // 0 - 599\n  @media (max-width: ", ") {\n    height: 4vh;\n  }\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 500;\n\n  display: flex;\n  justify-content: space-between;\n  padding: .8rem 2rem;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n\n  height: 8vh;\n\n  // 1280 - 1919\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 7vh;\n  }\n\n  // 960 - 1279\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 6vh;\n  }\n\n  // 600 - 959\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 5vh;\n  }\n\n  // 0 - 599\n  @media (max-width: ", ") {\n    height: 4vh;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.common.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.shadows[2];
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.xl - 1 + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.lg + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.lg - 1 + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.md + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.md - 1 + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.sm + 'px';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.values.sm - 1 + 'px';
});
var HeaderLeft = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  & > *:not(:last-child) {\n    margin-right: 4rem;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  & > *:not(:last-child) {\n    margin-right: 4rem;\n  }\n"])));
var LogoStyled = styled(Logo)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & img {\n    width: 10rem;\n    object-fit: contain;\n  }\n\n  & :hover {\n    cursor: pointer;\n  }\n"], ["\n  & img {\n    width: 10rem;\n    object-fit: contain;\n  }\n\n  & :hover {\n    cursor: pointer;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutStyles } from '../../core/styles/common/layout';
import { styled } from '@mui/material/styles';
export var UsersListItem = function (props) {
    var activateEdit = props.activateEdit, user = props.user;
    return (_jsxs(Container, __assign({ onClick: function () { return activateEdit(user); } }, { children: [_jsxs(Id, { children: ["User ID: ", _jsx("b", { children: user.id })] }), _jsxs(Name, { children: ["Login: ", _jsx("b", { children: user.username })] }), _jsxs(Name, { children: ["User name: ", _jsx("b", { children: user.displayName })] })] })));
};
// * Styles
var Container = styled(LayoutStyles.PaperColor)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: start;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", "\n  }\n"], ["\n  display: flex;\n  align-items: start;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", "\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.custom.bluePale;
});
var Id = styled('span')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  width: 8rem;\n  margin-right: 1rem;\n"], ["\n  display: inline-block;\n  width: 8rem;\n  margin-right: 1rem;\n"])));
var Name = styled('span')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  width: 25rem;\n  margin-right: 1rem;\n"], ["\n  display: inline-block;\n  width: 25rem;\n  margin-right: 1rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

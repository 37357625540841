export var AuthActionType;
(function (AuthActionType) {
    AuthActionType["SIGN_IN"] = "auth/SIGN_IN";
    AuthActionType["SIGN_IN_SUCCESS"] = "auth/SIGN_IN_SUCCESS";
    AuthActionType["SIGN_IN_FAIL"] = "auth/SIGN_IN_FAIL";
    AuthActionType["SAVE_DICTIONARIES"] = "auth/SAVE_DICTIONARIES";
    AuthActionType["LOGOUT"] = "auth/LOGOUT";
})(AuthActionType || (AuthActionType = {}));
export var authAction = {
    signIn: function (signInValues) { return ({
        type: AuthActionType.SIGN_IN,
        payload: {
            signInValues: signInValues
        }
    }); },
    signInSuccess: function (userData) { return ({
        type: AuthActionType.SIGN_IN_SUCCESS,
        payload: {
            userData: userData,
        }
    }); },
    signInFail: function () { return ({
        type: AuthActionType.SIGN_IN_FAIL
    }); },
    saveDictionaries: function (dictionaries) { return ({
        type: AuthActionType.SAVE_DICTIONARIES,
        payload: {
            dictionaries: dictionaries
        }
    }); },
    logout: function () { return ({
        type: AuthActionType.LOGOUT
    }); },
};

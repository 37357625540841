import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GreetingsPage } from '../../auth/GreetingsPage';
import { useSelector } from 'react-redux';
import { Users } from '../../users/Users';
export var RouterPath;
(function (RouterPath) {
    RouterPath["mainPage"] = "/main";
})(RouterPath || (RouterPath = {}));
export var AppRouter = function () {
    var _a = useSelector(function (state) { return state.auth; }), dictionaries = _a.dictionaries, userData = _a.userData;
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: userData && userData.accessRights.includes('UserManagement') && dictionaries ? _jsx(Navigate, { to: RouterPath.mainPage, replace: true }) : _jsx(GreetingsPage, {}) }), _jsx(Route, { path: RouterPath.mainPage, element: userData && userData.accessRights.includes('UserManagement') && dictionaries ? _jsx(Users, {}) : _jsx(Navigate, { to: "/", replace: true }) }), _jsx(Route, { element: _jsx("div", { children: "404" }) })] }) }));
};

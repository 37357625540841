var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { appAction } from '../../../features/app/_BLL/actions';
import { useActions } from '../../BLL/createAction';
import { keyframes } from '@emotion/react';
import { css, styled } from '@mui/material/styles';
export var NotificationSingle = memo(function (props) {
    var id = props.id, message = props.message, type = props.type;
    // Actions
    var deleteNotification = useActions(appAction).deleteNotification;
    // Local state
    var _a = useState(false), close = _a[0], setClose = _a[1];
    var _b = useState(0), barWidth = _b[0], setBarWidth = _b[1];
    var _c = useState(null), intervalId = _c[0], setIntervalId = _c[1];
    var handleStartTimer = useCallback(function () {
        var id = setInterval(function () {
            setBarWidth(function (prevState) {
                if (prevState < 100) {
                    return prevState + 0.5;
                }
                return prevState;
            });
        }, 20);
        setIntervalId(id);
    }, []);
    var handlePauseTimer = useCallback(function () {
        clearInterval(intervalId);
    }, [intervalId]);
    useEffect(function () {
        handleStartTimer();
    }, [handleStartTimer]);
    useEffect(function () {
        var handleCloseNotification = function () {
            handlePauseTimer();
            setClose(true);
            setTimeout(function () {
                deleteNotification(id);
            }, 400);
        };
        if (barWidth === 100) {
            handleCloseNotification();
        }
    }, [barWidth, deleteNotification, handlePauseTimer, id]);
    return (_jsxs(NotificationItem, __assign({ onMouseEnter: handlePauseTimer, onMouseLeave: handleStartTimer, exit: close }, { children: [_jsx("p", { children: message }), _jsx(Bar, { style: { width: "".concat(barWidth, "%") }, type: type })] })));
});
// Animations
var slideLeft = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    margin-left: 120%;\n  }\n\n  100% {\n    margin-left: 0;\n  }\n"], ["\n  0% {\n    margin-left: 120%;\n  }\n\n  100% {\n    margin-left: 0;\n  }\n"])));
var slideRight = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    margin-left: 0;\n  }\n\n  100% {\n    margin-left: 120%;\n  }\n"], ["\n  0% {\n    margin-left: 0;\n  }\n\n  100% {\n    margin-left: 120%;\n  }\n"])));
// * Styles
var Bar = styled('div')(function (_a) {
    var theme = _a.theme, type = _a.type;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: .5rem;\n\n  background-color: ", "\n"], ["\n  height: .5rem;\n\n  background-color: ", "\n"])), (function () {
        if (type === 'info')
            return theme.palette.primary.main;
        if (type === 'success')
            return theme.palette.success.main;
        if (type === 'warning')
            return theme.palette.warning.main;
        if (type === 'error')
            return theme.palette.error.main;
    })());
});
var NotificationItem = styled('div')(function (_a) {
    var theme = _a.theme, exit = _a.exit;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: hidden;\n\n  margin-bottom: 1rem;\n\n  width: 300px;\n\n  box-shadow: ", ";\n\n  background-color: ", ";\n\n  animation: ", " .5s linear forwards;\n  animation: ", ";\n\n  & p {\n    margin: 0;\n    padding: 1rem;\n  }\n"], ["\n  overflow: hidden;\n\n  margin-bottom: 1rem;\n\n  width: 300px;\n\n  box-shadow: ", ";\n\n  background-color: ", ";\n\n  animation: ", " .5s linear forwards;\n  animation: ", ";\n\n  & p {\n    margin: 0;\n    padding: 1rem;\n  }\n"])), theme.shadows['1'], theme.palette.common.white, slideLeft, exit && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["", " .5s linear forwards"], ["", " .5s linear forwards"])), slideRight));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

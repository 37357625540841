var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { SearchBarResult } from './SearchBarResult';
import { css, styled } from '@mui/material/styles';
import { ButtonRounded } from '../../../core/components/ui-elements/buttons/buttons';
import { LinearProgress, TextField } from '@mui/material';
import { RequestStatus } from '../../../core/api/api';
import { useActions } from '../../../core/BLL/createAction';
import { useOnclickAwayHandler } from '../../../hooks/useOnclickAwayHandler';
import { usePrevious } from '../../../hooks/usePrevious';
import { searchCompaniesAction } from '../_BLL/searchCompanies/actions';
import CircularProgress from '@mui/material/CircularProgress';
export var SearchBar = function (divProps) {
    // * Selectors
    var _a = useSelector(function (state) { return state.searchCompanies; }), search = _a.search, pagination = _a.pagination, companies = _a.companies, status = _a.status;
    var pageIndex = pagination.pageIndex, pageSize = pagination.pageSize;
    // * Actions
    var _b = useActions(searchCompaniesAction), saveSearch = _b.saveSearch, searchRequest = _b.searchRequest, clearResults = _b.clearResults, clearAll = _b.clearAll, paginationIncrement = _b.paginationIncrement;
    // * History API
    var prevSearch = usePrevious(search);
    var prevPageIndex = usePrevious(pageIndex);
    var searchZone = useRef(null);
    useOnclickAwayHandler(searchZone, clearAll);
    useEffect(function () {
        clearAll();
    }, [clearAll]);
    // * Component logic
    useEffect(function () {
        var searchChange = search !== prevSearch;
        var pageNumberChange = pageIndex !== prevPageIndex;
        var body = {
            searchPrefix: search,
            pagination: {
                pageSize: pageSize,
                pageIndex: pageIndex,
            },
        };
        if (searchChange || pageNumberChange) {
            if (!search)
                clearResults();
            if (prevSearch !== undefined) {
                var url = 'companiesLookup';
                if (searchChange && search.trim() !== '' && search) {
                    searchRequest(url, body);
                    clearResults();
                }
                if (pageNumberChange && search.trim() !== '' && search) {
                    searchRequest(url, body);
                }
            }
        }
    }, [search, pageIndex, companies, prevSearch, prevPageIndex, pageSize, clearAll, clearResults, searchRequest]);
    return (_jsxs(Container, __assign({}, divProps, { ref: searchZone }, { children: [_jsxs(InputContainer, { children: [_jsx(TextFieldStyled, { type: "text", size: "small", name: "search", placeholder: "Search...", autoComplete: "off", variant: "outlined", value: search, onChange: function (event) { return saveSearch(event.target.value); } }), status === RequestStatus.loading && _jsx(LinearProgressStyled, {})] }), companies.length > 0 ?
                _jsxs(SearchResults, { children: [_jsx(FixedSizeListStyled, __assign({ itemData: companies, height: 300, width: '100%', itemSize: 30, itemCount: companies.length }, { children: function (props) { return (_jsx(SearchBarResult, __assign({}, props, { data: companies[props.index] }))); } })), status !== RequestStatus.loading ?
                            _jsx(ButtonRoundedStyled, __assign({ onClick: paginationIncrement }, { children: "SHOW MORE" })) :
                            _jsx(CircularProgress, { style: { marginLeft: '40px' }, size: 30 })] })
                : null] })));
};
// * Styles
var Container = styled('div')(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"]))));
var InputContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n\n  & > div {\n    flex-grow: 1;\n  }\n\n  & input {\n    background-color: ", ";\n    border-radius: .4rem;\n    box-shadow: ", ";\n  }\n\n  // MUI modifications\n  & .MuiOutlinedInput-root {\n    &:hover fieldset {\n      border-color: ", ";\n    }\n\n    &.Mui-focused fieldset {\n      border-color: ", ";\n      border-width: 1px;\n    }\n"], ["\n  display: flex;\n\n  & > div {\n    flex-grow: 1;\n  }\n\n  & input {\n    background-color: ", ";\n    border-radius: .4rem;\n    box-shadow: ", ";\n  }\n\n  // MUI modifications\n  & .MuiOutlinedInput-root {\n    &:hover fieldset {\n      border-color: ", ";\n    }\n\n    &.Mui-focused fieldset {\n      border-color: ", ";\n      border-width: 1px;\n    }\n"])), theme.palette.common.white, theme.shadows[1], theme.palette.primary.main, theme.palette.primary.main);
});
var LinearProgressStyled = styled(LinearProgress)(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n"], ["\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n"]))));
var TextFieldStyled = styled(TextField)(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & .MuiInputBase-inputMarginDense {\n    padding: 1rem 1.5rem;\n  }\n"], ["\n  & .MuiInputBase-inputMarginDense {\n    padding: 1rem 1.5rem;\n  }\n"]))));
var ButtonRoundedStyled = styled(ButtonRounded)(css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 1rem;\n"], ["\n  margin-left: 1rem;\n"]))));
var SearchResults = styled('div')(function (_a) {
    var theme = _a.theme;
    return (css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 100;\n\n  padding-bottom: 1.2rem;\n\n  height: fit-content;\n  width: 100%;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n"], ["\n  position: absolute;\n  z-index: 100;\n\n  padding-bottom: 1.2rem;\n\n  height: fit-content;\n  width: 100%;\n\n  background-color: ", ";\n\n  box-shadow: ", ";\n"])), theme.palette.common.white, theme.shadows[2]));
});
var FixedSizeListStyled = styled(FixedSizeList)(css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css, styled } from '@mui/material/styles';
var Page = styled('div')(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n\n  min-height: 100vh;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n\n  min-height: 100vh;\n"]))));
var Container = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 2rem;\n\n  // Based on Header component's height (100 - header)\n  height: 92vh;\n\n  // 1280 - 1919\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 93vh;\n  }\n\n  // 960 - 1279\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 94vh;\n  }\n\n  // 600 - 959\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 95vh;\n  }\n\n  // 0 - 599\n  @media (max-width: ", ") {\n    height: 96vh;\n  }\n"], ["\n  padding: 2rem;\n\n  // Based on Header component's height (100 - header)\n  height: 92vh;\n\n  // 1280 - 1919\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 93vh;\n  }\n\n  // 960 - 1279\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 94vh;\n  }\n\n  // 600 - 959\n  @media (max-width: ", ")\n  and (min-width: ", ") {\n    height: 95vh;\n  }\n\n  // 0 - 599\n  @media (max-width: ", ") {\n    height: 96vh;\n  }\n"])), theme.breakpoints.values.xl - 1 + 'px', theme.breakpoints.values.lg + 'px', theme.breakpoints.values.lg - 1 + 'px', theme.breakpoints.values.md + 'px', theme.breakpoints.values.md - 1 + 'px', theme.breakpoints.values.sm + 'px', theme.breakpoints.values.sm - 1 + 'px');
});
var Inner = styled('div')(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"]))));
var GridColumn = styled('div')(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"]))));
var GridRow = styled('div')(css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  gap: 1rem;\n\n  width: 100%;\n"], ["\n  display: flex;\n  gap: 1rem;\n\n  width: 100%;\n"]))));
var Paper = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1rem;\n\n  background-color: ", ";\n  border-radius: ", ";\n"], ["\n  padding: 1rem;\n\n  background-color: ", ";\n  border-radius: ", ";\n"])), theme.palette.common.white, theme.shape.borderRadius + 'px');
});
var PaperBW = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 1px solid ", ";\n"], ["\n  border: 1px solid ", ";\n"])), theme.palette.grey[200]);
});
var PaperColor = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tborder: 1px solid ", ";\n"], ["\n\tborder: 1px solid ", ";\n"])), theme.palette.custom.mainBorder);
});
var Card = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 1rem;\n\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n"], ["\n  padding: 1rem;\n\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n"])), theme.palette.common.white, theme.shadows[1], theme.shape.borderRadius + 'px');
});
var PreloaderWrapper = styled('div')(css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  flex: 1 0 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  flex: 1 0 100%;\n"]))));
// Button wrappers
var ButtonWrapperColumn = styled('div')(css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  & div:not(:last-child), button:not(:last-child) {\n    margin-bottom: 1rem;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  & div:not(:last-child), button:not(:last-child) {\n    margin-bottom: 1rem;\n  }\n"]))));
var ButtonWrapperRow = styled('div')(css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n\n  & div:not(:last-child), button:not(:last-child) {\n    margin-right: 1rem;\n  }\n"], ["\n  display: flex;\n\n  & div:not(:last-child), button:not(:last-child) {\n    margin-right: 1rem;\n  }\n"]))));
export var LayoutStyles = {
    Page: Page,
    Container: Container,
    Inner: Inner,
    GridColumn: GridColumn,
    GridRow: GridRow,
    PaperBW: PaperBW,
    PaperColor: PaperColor,
    Card: Card,
    PreloaderWrapper: PreloaderWrapper,
    ButtonWrapperColumn: ButtonWrapperColumn,
    ButtonWrapperRow: ButtonWrapperRow
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

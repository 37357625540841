var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NotificationSingle } from './NotificationSingle';
import styled from '@emotion/styled';
export var NotificationProvider = function (props) {
    var notifications = props.notifications;
    return (_jsx(NotificationWrapper, { children: notifications.map(function (note) { return (_jsx(NotificationSingle, { id: note.id, message: note.message, type: note.type }, note.id)); }) }));
};
// Styles
var NotificationWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 1rem;\n  right: 1rem;\n\n  width: 300px;\n\n  z-index: 1000;\n"], ["\n  position: fixed;\n  bottom: 1rem;\n  right: 1rem;\n\n  width: 300px;\n\n  z-index: 1000;\n"])));
var templateObject_1;

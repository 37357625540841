var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useFormContext } from 'react-hook-form';
export var SearchBarResult = function (props) {
    var index = props.index, // Indexes of react-window
    style = props.style, // Default styles of react-window
    data = props.data;
    var _a = useFormContext(), watch = _a.watch, setValue = _a.setValue;
    var copperCompanies = watch('copperCompanies');
    var onClick = function (accessType) {
        if (!copperCompanies.find(function (company) { return company.id === data.id && company.accessType === accessType; })) {
            setValue('copperCompanies', __spreadArray(__spreadArray([], copperCompanies, true), [{
                    id: data.id,
                    displayName: data.name,
                    accessType: accessType
                }], false));
        }
    };
    return (_jsx(Container, __assign({ style: style }, { children: _jsxs(Inner, { children: [_jsxs(ResultInnerTitle, { children: [data.id, ": ", data.name] }), _jsxs("div", { children: [_jsx(Button, __assign({ onClick: function () { return onClick(1); } }, { children: "Advanced" })), _jsx(Button, __assign({ onClick: function () { return onClick(2); } }, { children: "Certified" }))] })] }) }), index));
};
// Styles
var Container = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding: 0 1rem 0;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n    border-bottom: 1px solid ", ";\n  }\n"], ["\n\tpadding: 0 1rem 0;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n    border-bottom: 1px solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary.main;
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.primary.main;
});
var Inner = styled('span')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: .9rem;\n  height: 30px; // Match the height of the row in react window to align the text vertically at center.\n\n  & > svg {\n    margin-left: .5rem;\n    width: 1.2rem;\n    height: 1.2rem;\n    fill: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: .9rem;\n  height: 30px; // Match the height of the row in react window to align the text vertically at center.\n\n  & > svg {\n    margin-left: .5rem;\n    width: 1.2rem;\n    height: 1.2rem;\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary.main;
});
var ResultInnerTitle = styled('span')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightBold;
});
var templateObject_1, templateObject_2, templateObject_3;

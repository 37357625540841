import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';
import { loadState, saveState } from './localStorage';
import { composeWithDevTools } from '@redux-devtools/extension';
import { initialState as app } from '../../features/app/_BLL/reducer';
import { initialState as users } from '../../features/users/_BLL/reducer';
import { initialState as searchCompanies } from '../../features/users/_BLL/searchCompanies/reducer';
var sagaMiddleware = createSagaMiddleware();
var persistedState = loadState();
var composeEnhancers = composeWithDevTools({ trace: true });
export var store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(sagaMiddleware)));
store.subscribe(function () {
    saveState({
        app: app,
        auth: store.getState().auth,
        users: users,
        searchCompanies: searchCompanies
    });
});
sagaMiddleware.run(rootSaga);

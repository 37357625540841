export var localStorageName = process.env.REACT_APP_LOCAL_STORAGE_NAME || 'man_state_26012022-t';
export var loadState = function () {
    try {
        var serializedState = localStorage.getItem(localStorageName);
        return serializedState ? JSON.parse(serializedState) : undefined;
    }
    catch (error) {
        return undefined;
    }
};
export var saveState = function (state) {
    try {
        var serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageName, serializedState);
    }
    catch (error) {
        // Ignore
    }
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
export var PasswordInput = function (props) {
    var variant = props.variant, size = props.size, error = props.error, helperText = props.helperText, restProps = __rest(props, ["variant", "size", "error", "helperText"]);
    var _a = useState(false), showPassword = _a[0], setShowPassword = _a[1];
    var handleClickShowPassword = function () { return setShowPassword(!showPassword); };
    var handleMouseDownPassword = function (e) {
        e.preventDefault();
    };
    return (_jsxs(FormControl, __assign({ variant: variant, error: error, size: size }, { children: [_jsx(InputLabel, __assign({ htmlFor: "password" }, { children: "Password" })), _jsx(OutlinedInput, __assign({}, restProps, { type: showPassword ? 'text' : 'password', endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end" }, { children: showPassword ? _jsx(Visibility, {}) : _jsx(VisibilityOff, {}) })) })), labelWidth: 70 })), helperText && _jsx(FormHelperText, { children: helperText })] })));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { css, styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import { ButtonRounded } from '../../core/components/ui-elements/buttons/buttons';
export var AccessDataPoints = function (props) {
    var label = props.label, fieldName = props.fieldName, dictionary = props.dictionary;
    var _a = useFormContext(), control = _a.control, setValue = _a.setValue;
    var handleChange = function (datapointId, activeDatapointIds) {
        var accessIdIsActive = activeDatapointIds.includes(datapointId);
        if (accessIdIsActive) {
            setValue(fieldName, activeDatapointIds.filter(function (id) { return id !== datapointId; }));
        }
        else {
            setValue(fieldName, __spreadArray(__spreadArray([], activeDatapointIds, true), [datapointId], false));
        }
    };
    var allDatapointIds = dictionary.map(function (dictionaryItem) { return dictionaryItem.datapointId; });
    var selectAll = function () {
        setValue(fieldName, allDatapointIds);
    };
    // * Render
    return (_jsx(Controller, { name: fieldName, control: control, render: function (_a) {
            var field = _a.field;
            return (_jsxs(FormControlStyled, { children: [_jsx(FormLabel, { children: label }), _jsx(ButtonRounded, __assign({ onClick: selectAll }, { children: "Select all" })), dictionary
                        .map(function (dictionaryItem) { return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: field.value.includes(dictionaryItem.datapointId), onChange: function () { return handleChange(dictionaryItem.datapointId, field.value); } }), label: dictionaryItem.propertyDescription }, dictionaryItem.datapointId)); })] }));
        } }));
};
// * SC
var FormControlStyled = styled(FormControl)(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > label:first-child, button {\n    margin-bottom: 1rem;\n  }\n"], ["\n  & > label:first-child, button {\n    margin-bottom: 1rem;\n  }\n"]))));
var templateObject_1;

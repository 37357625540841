import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { appAction } from './_BLL/actions';
import { Modal } from '../../core/components/Modal/Modal';
import { CriticalErrorModalContent } from './components/CriticalErrorModalContent';
import { NotificationProvider } from '../../core/components/notification/NotificationProvider';
import { useActions } from '../../core/BLL/createAction';
export var NotificationController = function () {
    // Selectors
    var _a = useSelector(function (state) { return state.app; }), error = _a.error, notifications = _a.notifications;
    // Actions
    var clearError = useActions(appAction).clearError;
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { toggleModal: clearError, Content: _jsx(CriticalErrorModalContent, { error: error }), show: !!error, width: 40, closeOnBackdrop: false }), _jsx(NotificationProvider, { notifications: notifications })] }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from '@mui/material/styles';
var TextWrapper = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var Title = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n\n  font-weight: ", ";\n\n  text-transform: ", ";\n\n  color: ", ";\n\n  white-space: pre-wrap;\n\n  margin-bottom: ", ";\n\n  line-height: 1.3;\n"], ["\n  font-size: ", ";\n\n  font-weight: ", ";\n\n  text-transform: ", ";\n\n  color: ", ";\n\n  white-space: pre-wrap;\n\n  margin-bottom: ", ";\n\n  line-height: 1.3;\n"])), function (_a) {
    var size = _a.size;
    if (size === 'xs')
        return '.6rem';
    if (size === 's')
        return '.8rem';
    if (size === 'm')
        return '1rem';
    if (size === 'l')
        return '1.2rem';
    if (size === 'xl')
        return '1.4rem';
    if (size === 'xxl')
        return '1.6rem';
    if (size === 'xxxl')
        return '1.8rem';
    else
        return '1.2rem'; // Default value
}, function (_a) {
    var weight = _a.weight;
    if (weight === 'thin')
        return '200';
    if (weight === 'regular')
        return '400';
    if (weight === 'bold')
        return '600';
    if (weight === 'extraBold')
        return '800';
    else
        return '600'; // Default value
}, function (_a) {
    var transform = _a.transform;
    if (transform === 'none')
        return 'none';
    if (transform === 'capital')
        return 'capitalize';
    if (transform === 'upper')
        return 'uppercase';
    if (transform === 'lower')
        return 'lowercase';
    else
        return 'none'; // Default value
}, function (_a) {
    var color = _a.color, theme = _a.theme;
    if (color === 'primary')
        return theme.palette.primary.main;
    if (color === 'secondary')
        return theme.palette.secondary.main;
    else
        return theme.palette.common.black; // Default value
}, function (_a) {
    var marginBottom = _a.marginBottom;
    if (marginBottom === 'xs')
        return '.6rem';
    if (marginBottom === 's')
        return '.8rem';
    if (marginBottom === 'm')
        return '1rem';
    if (marginBottom === 'l')
        return '1.2rem';
    if (marginBottom === 'xl')
        return '1.4rem';
    if (marginBottom === 'xxl')
        return '1.6rem';
    if (marginBottom === 'xxxl')
        return '1.8rem';
    else
        return '0'; // Default value
});
var Subtitle = styled('div')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 400;\n  color: ", ";\n\n  line-height: 1.6;\n"], ["\n  font-weight: 400;\n  color: ", ";\n\n  line-height: 1.6;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.palette.primary.dark;
});
var SubtitleXL = styled(Subtitle)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.6rem;\n"], ["\n  font-size: 1.6rem;\n"])));
var SubtitleL = styled(Subtitle)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 1.4rem;\n"], ["\n  font-size: 1.4rem;\n"])));
var SubtitleM = styled(Subtitle)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 1.2rem;\n"], ["\n  font-size: 1.2rem;\n"])));
var SubtitleS = styled(Subtitle)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1rem;\n"], ["\n  font-size: 1rem;\n"])));
var Caption = styled('div')(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n\n  color: ", ";\n\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n\n  color: ", ";\n\n  margin-bottom: ", ";\n"])), function (_a) {
    var size = _a.size;
    if (size === 'xs')
        return '.5rem';
    if (size === 's')
        return '.7rem';
    if (size === 'm')
        return '.9rem';
    if (size === 'l')
        return '1.1rem';
    if (size === 'xl')
        return '1.3rem';
    if (size === 'xxl')
        return '1.5rem';
    if (size === 'xxxl')
        return '1.7rem';
    else
        return size; // Default value
}, function (_a) {
    var theme = _a.theme;
    return theme.palette.grey[500];
}, function (_a) {
    var marginBottom = _a.marginBottom;
    if (marginBottom === 'xs')
        return '.6rem';
    if (marginBottom === 's')
        return '.8rem';
    if (marginBottom === 'm')
        return '1rem';
    if (marginBottom === 'l')
        return '1.2rem';
    if (marginBottom === 'xl')
        return '1.4rem';
    if (marginBottom === 'xxl')
        return '1.6rem';
    if (marginBottom === 'xxxl')
        return '1.8rem';
    else
        return '0'; // Default value
});
var Paragraph = styled('p')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 1.2rem;\n  white-space: pre;\n"], ["\n  margin-bottom: 1.2rem;\n  white-space: pre;\n"])));
export var TypographyStyles = {
    TextWrapper: TextWrapper,
    Title: Title,
    SubtitleXL: SubtitleXL,
    SubtitleL: SubtitleL,
    SubtitleM: SubtitleM,
    SubtitleS: SubtitleS,
    Caption: Caption,
    Paragraph: Paragraph
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from 'redux-saga/effects';
import { UrlAPI, getRequest, postRequest, RequestStatus } from '../../../core/api/api';
import { standardError } from '../../../helpers/sagaHelpers';
import { authAction, AuthActionType } from './actions';
// Workers
export function signIn(action) {
    var userData, dictionaries, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(postRequest, UrlAPI.login, action.payload.signInValues)];
            case 1:
                userData = _a.sent();
                return [4 /*yield*/, put(authAction.signInSuccess(userData))];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(getRequest, UrlAPI.dictionaries)];
            case 3:
                dictionaries = _a.sent();
                return [4 /*yield*/, put(authAction.saveDictionaries(dictionaries))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_1 = _a.sent();
                return [4 /*yield*/, standardError(error_1, AuthActionType.SIGN_IN_FAIL)];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// Watchers
export function authWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(AuthActionType.SIGN_IN, signIn)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var initialState = {
    userData: null,
    dictionaries: null,
    registrationStep: 0,
    status: RequestStatus.still,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionType.SIGN_IN: {
            return __assign(__assign({}, state), { status: RequestStatus.loading });
        }
        case AuthActionType.SIGN_IN_SUCCESS: {
            var payload = action.payload;
            return __assign(__assign({}, state), { userData: payload.userData });
        }
        case AuthActionType.SIGN_IN_FAIL: {
            return __assign(__assign({}, state), { status: RequestStatus.still });
        }
        case AuthActionType.SAVE_DICTIONARIES: {
            var payload = action.payload;
            return __assign(__assign({}, state), { dictionaries: payload.dictionaries, status: RequestStatus.still });
        }
        case AuthActionType.LOGOUT: {
            return __assign(__assign({}, state), { userData: null, status: RequestStatus.still, dictionaries: null });
        }
        default:
            return state;
    }
}

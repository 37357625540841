var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Header } from '../../core/components/layout/Header/Header';
import { useActions } from '../../core/BLL/createAction';
import { usersACTION } from './_BLL/actions';
import { useSelector } from 'react-redux';
import { UserForm } from './UserForm';
import { Modal } from '../../core/components/Modal/Modal';
import { LayoutStyles } from '../../core/styles/common/layout';
import { css, styled } from '@mui/material/styles';
import { UsersListItem } from './UsersListItem';
import { ButtonRounded } from '../../core/components/ui-elements/buttons/buttons';
import { UserSearch } from './UserSearch';
import { RequestStatus } from '../../core/api/api';
import { LinearProgress } from '@mui/material';
export var Users = function () {
    // * Styles
    var LS = LayoutStyles;
    var users = useSelector(function (state) { return state.users.users; });
    var status = useSelector(function (state) { return state.users.status; });
    var _a = useActions(usersACTION), getUsers = _a.getUsers, addOrEditUser = _a.addOrEditUser;
    useEffect(function () {
        getUsers();
    }, []);
    // * Modal state
    var modalInitialState = {
        show: false,
        userData: null
    };
    var _b = useState(modalInitialState), modal = _b[0], setModal = _b[1];
    var toggleModal = function () {
        setModal(modalInitialState);
    };
    var activateEdit = function (user) {
        setModal({
            show: true,
            userData: user
        });
    };
    var addOrEditUserHandler = function (user) {
        addOrEditUser(user);
    };
    // ! HARDCODE - User parameters can change in the future, requiring update of this object.
    var initialNewUserFormValues = {
        id: -1,
        username: '',
        displayName: '',
        password: null,
        maxPageSize: 5000,
        maxExportSize: 10000,
        goldAccessRights: [],
        silverAccessRights: [],
        managementAccessRights: [],
        specialAccessDatapoints: [],
        copperCompanies: []
    };
    // * Users filter
    var _c = useState(users), filteredUsers = _c[0], setFilteredUsers = _c[1];
    var filterUsers = function (userName) {
        if (users === null) {
            // Ignore filter
        }
        else {
            var filteredUsers_1 = users.filter(function (user) { return user.username.includes(userName); });
            filteredUsers_1.length > 0 ? setFilteredUsers(filteredUsers_1) : setFilteredUsers(null);
        }
    };
    useEffect(function () {
        // reset filter when users change
        setFilteredUsers(users);
    }, [users]);
    return (_jsxs(_Fragment, { children: [modal.userData && (_jsx(Modal, { show: modal.show, toggleModal: toggleModal, Content: _jsx(UserForm, { user: modal.userData, addOrEditUser: addOrEditUserHandler, toggleModal: toggleModal }) })), _jsx(Header, {}), _jsxs(LS.Container, { children: [_jsx(ButtonRoundedStyled, __assign({ onClick: function () { return activateEdit(initialNewUserFormValues); } }, { children: "Add new user" })), _jsx(UserSearch, { filterUsers: filterUsers }), _jsx(UsersContainer, { children: _jsx(LS.GridColumn, { children: status === RequestStatus.loading
                                ? _jsx(LinearProgress, {})
                                : filteredUsers && filteredUsers.map(function (user) { return (_jsx(UsersListItem, { user: user, activateEdit: activateEdit }, user.id)); }) }) })] })] }));
};
// * Styles
var UsersContainer = styled(LayoutStyles.PaperColor)(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-x: auto;\n  height: 70vh;\n"], ["\n  overflow-x: auto;\n  height: 70vh;\n"]))));
var ButtonRoundedStyled = styled(ButtonRounded)(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"]))));
var templateObject_1, templateObject_2;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { localStorageName } from '../BLL/localStorage';
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus[RequestStatus["still"] = 0] = "still";
    RequestStatus[RequestStatus["loading"] = 1] = "loading";
    RequestStatus[RequestStatus["fail"] = 2] = "fail";
})(RequestStatus || (RequestStatus = {}));
export var UrlAPI;
(function (UrlAPI) {
    // Authentication
    UrlAPI["login"] = "login";
    UrlAPI["logout"] = "logout";
    UrlAPI["dictionaries"] = "dictionaries";
})(UrlAPI || (UrlAPI = {}));
// Prod - iryvcwhxhg
// Dev - uxn71jhrb1
// Special - dr3t6su5bd
var host = process.env.REACT_APP_HOST || 'https://iryvcwhxhg.execute-api.us-east-2.amazonaws.com/Prod';
// TODO: Type the class properly.
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(message, severity, incidentId) {
        var params = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            params[_i - 3] = arguments[_i];
        }
        var _this = _super.apply(this, params) || this;
        _this.severity = severity;
        _this.message = message;
        _this.incidentId = incidentId;
        return _this;
    }
    return ResponseError;
}(Error));
var handleResponse = function (parsed) {
    var severity = parsed.severity, incidentId = parsed.incidentId;
    if (severity) {
        if (severity === 'Information')
            throw new ResponseError(parsed.message, severity);
        if (severity === 'Warning')
            throw new ResponseError(parsed.message, severity);
        if (severity === 'Error')
            throw new ResponseError(parsed.message, severity);
        if (severity === 'AccessDenied')
            throw new ResponseError(parsed.message, severity);
        if (severity === 'Critical')
            throw new ResponseError(parsed.message, severity, incidentId);
    }
};
var getAuth = function () {
    var state = localStorage.getItem(localStorageName);
    var parsed = state ? JSON.parse(state) : null;
    return parsed ? parsed.auth : null;
};
var getHeaders = function (auth) {
    var token = auth && auth.userData ? auth.userData.token : '';
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Headers': '*',
        'x-api-token': token,
    };
};
var get = function (url, params, host) { return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                auth = getAuth();
                return [4 /*yield*/, fetch("".concat(host, "/").concat(url).concat(params ? '?' + params : ''), {
                        method: 'GET',
                        headers: getHeaders(auth),
                        referrerPolicy: 'no-referrer',
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var post = function (url, data, host) { return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                auth = getAuth();
                return [4 /*yield*/, fetch("".concat(host, "/").concat(url), {
                        method: 'POST',
                        mode: 'cors',
                        headers: getHeaders(auth),
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(data),
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var del = function (url, params, host) { return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                auth = getAuth();
                return [4 /*yield*/, fetch("".concat(host, "/").concat(url).concat(params ? '?' + params : ''), {
                        method: 'DELETE',
                        headers: getHeaders(auth),
                        referrerPolicy: 'no-referrer',
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export function getRequest(url, params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, parsed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get(url, params, host)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    parsed = _a.sent();
                    handleResponse(parsed);
                    return [2 /*return*/, parsed];
            }
        });
    });
}
export function deleteRequest(url, params) {
    return __awaiter(this, void 0, void 0, function () {
        var response, parsed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, del(url, params, host)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    parsed = _a.sent();
                    handleResponse(parsed);
                    return [2 /*return*/, parsed];
            }
        });
    });
}
// Backend expects some POST queries to have both POST and GET style parameters
export function postRequest(url, data, params) {
    return __awaiter(this, void 0, void 0, function () {
        var fullUrl, response, parsed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fullUrl = url + (params ? '?' + params : '');
                    return [4 /*yield*/, post(fullUrl, data, host)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    parsed = _a.sent();
                    handleResponse(parsed);
                    return [2 /*return*/, parsed];
            }
        });
    });
}

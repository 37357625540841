var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Button, css, styled } from '@mui/material';
export var ButtonSquared = memo(function (props) {
    var buttonProps = __rest(props, []);
    return (_jsx(ButtonSquaredMain, __assign({ variant: "contained", color: "inherit" }, buttonProps)));
});
export var ButtonRounded = memo(function (props) {
    var buttonProps = __rest(props, []);
    return (_jsx(ButtonRoundedMain, __assign({ variant: "outlined", color: "primary" }, buttonProps)));
});
// * Styles
var ButtonSquaredMain = styled(Button)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n"], ["\n  color: ", ";\n  background-color: ", ";\n"])), theme.palette.getContrastText(theme.palette.primary.main), theme.palette.primary.main);
});
var ButtonRoundedMain = styled(Button)(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: .4rem 2rem;\n\n  border-radius: 30px;\n\n  text-decoration: none\n"], ["\n  padding: .4rem 2rem;\n\n  border-radius: 30px;\n\n  text-decoration: none\n"]))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Logo } from '../../core/components/ui-elements/Logo';
import { SignInForm } from './components/SignInForm';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
export var SignInPage = function () {
    useEffect(function () {
        localStorage.clear();
    }, []);
    return (_jsx(_Fragment, { children: _jsx(Container, { children: _jsxs(PaperStyled, { children: [_jsx(LogoStyled, {}), _jsx(SignInForm, {})] }) }) }));
};
// Styles
var Container = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n"])));
var PaperStyled = styled(Paper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  padding: 2rem;\n\n  box-shadow: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  padding: 2rem;\n\n  box-shadow: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.shadows[3];
});
var LogoStyled = styled(Logo)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 2rem;\n\n\n  & img {\n    width: 200px;\n  }\n"], ["\n  margin-bottom: 2rem;\n\n\n  & img {\n    width: 200px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
